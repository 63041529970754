import * as React from "react";
import { Link } from "gatsby";

import gif404 from "../images/404gif.gif";
import { Button, Container } from "reactstrap";

import "../components/404.scss";
import "../components/GlobalStyle.scss";

// import DNNaeLogo from '../images/DNNaeLogo.svg'
// import DNNaeText from '../images/DNNaeText.svg'
// import BurgerComponent from "../components/burgerComponent/BurgerComponent"
import WebsiteNavigationBar from "../components/NavigationBar/WebsiteNavigationBar";
import { Helmet } from "react-helmet";

const NotFoundPage = () => {
  const selectedState = "";
  const buttonLabel = "Back to home";

  return (
    <div style={{ height: "100vh" }}>
      <Helmet title="DNNae | 404" />

      <Container
        style={{
          maxWidth: "1920px",
        }}
      >
        <WebsiteNavigationBar
          buttonStatus={false}
          selectedState=""
          burgerContainerStyle={{
            background: "#F2F9F5",
          }}
        />
        <div className="notFoundControl">
          <h1 id="notFoundTitleControl">Looks like you’re lost</h1>
          <img
            style={{
              width: "100%",
              maxWidth: "550px",
            }}
            src={gif404}
          ></img>
          <Link to="/">
            <Button color="primary" className="errorPageButton">
              {buttonLabel}
            </Button>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default NotFoundPage;
